<template>
  <div>

    <div class="card">
      <div class="card-body">

        <div class="position-relative wd-50">
          <input v-on:keyup.enter="loadProducts(1)" type="text" class="form-control ps-5" v-model="filters.search" placeholder="Search Product..."> <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
        </div>

        <br/>

        <button type="button" class="btn btn-sm btn-light ms-2" style="float: right;" title="Manage Columns" data-bs-toggle="modal" data-bs-target="#manageColumnsModal">
          <i class="bx bxs-grid me-0"></i>
        </button>
        <button type="button" style="float: right"  data-bs-toggle="modal" data-bs-target="#createProductModal" class="btn btn-sm btn-primary px-5">New Product</button>
        <pagination @search="loadProducts" :data="pagination"></pagination>

        <table id="example" class="table table-hover" style="width:100%">
          <thead>
          <tr>
            <th v-show="columns.name">Name</th>
            <th v-show="columns.type">Type</th>
            <th v-show="columns.cost">Cost</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="products.length < 1">
            <td colspan="2" class="text-center">No products found.</td>
          </tr>
          <tr v-for="product in products" :key="product.id" style="cursor: pointer;" @click="$router.push({name: 'admin.products.view', params: {id: product.id}})">
            <td v-show="columns.name">{{product.name}}</td>
            <td v-show="columns.type">{{product.type}}</td>
            <td v-show="columns.cost">{{product.cost}}</td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <th v-show="columns.name">Name</th>
            <th v-show="columns.type">Type</th>
            <th v-show="columns.cost">Cost</th>
          </tr>
          </tfoot>
        </table>

        <pagination @search="loadProducts" :data="pagination"></pagination>
      </div>
    </div>

    <!-- MODALS -->

    <div class="modal fade" id="createProductModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create Product</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 mb-2">
                <p>Fields marked <span class="text-danger">*</span> are required</p>
              </div>
              <div class="col-md-12 mb-2">
                <label for="productName" class="form-label">Name <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="productName" v-model="product.name">
              </div>
              <div class="col-md-12 mb-2">
                <label for="productType" class="form-label">Type</label>
                <input type="text" class="form-control" id="productType" v-model="product.type">
              </div>
              <div class="col-md-12 mb-2">
                <label for="productType" class="form-label">Cost</label>
                <input type="number" min="0.00" step="0.01" class="form-control" id="productCost" v-model="product.cost">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeCreateProductModal'>Close</button>
            <submit-button type="button" :background="'btn btn-primary'" :clicked="creating" @click="createProduct" text="Save changes"></submit-button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="manageColumnsModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Manage Columns</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>Please note these settings will be saved locally in your browser. If you clear your browser settings these settings may reset.</p>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="nameColumn" @change="updateColumnSettings" v-model="columns.name">
              <label class="form-check-label" for="nameColumn">Name</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="streetColumn" @change="updateColumnSettings" v-model="columns.type">
              <label class="form-check-label" for="streetColumn">Type</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="costColumn" @change="updateColumnSettings" v-model="columns.cost">
              <label class="form-check-label" for="costColumn">Cost</label>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeManageColumnsModal'>Close</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
/* global $ */
import axios from 'axios';
import Pagination from '../../../components/Pagination.vue';
import SubmitButton from '../../../components/SubmitButton.vue';
export default {
  components: { Pagination, SubmitButton },
  name: 'admin.products',
  data(){
    return {
      products: [],
      pagination: {
        lastPage: 0,
        page: 0,
        showing: 0,
        total: 0
      },
      product: {
        name: '',
        type: null,
        cost: 0.00
      },
      creating: false,
      filters: {
        search: ""
      },
      columns: {
        name: true,
        type: true,
        cost: true
      }
    }
  },
  mounted(){
    this.loadProducts();
    this.loadColumnSettings();
  },
  methods: {
    loadProducts(page = 1){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/product?page=${page}&search=${this.filters.search}`)
          .then(response => {
            this.products = response.data.products;
            this.pagination = response.data.pagination;
          })
          .catch(error => {
            this.$error('Failed to load products.', error);
          });
    },
    createProduct(){
      this.creating = true;
      axios.put(`${process.env.VUE_APP_API_URL}/v1/product`, this.product)
          .then(() => {
            $('#closeCreateProductModal').click();
            this.$success("Created new product");
            this.creating = false;
            this.product = {
              name: '',
              type: null
            }
            this.loadProducts(this.pagination.page);
          })
          .catch(error => {
            this.$error("Failed to create new product.", error);
            this.creating = false;
          })
    },
    updateColumnSettings(){
      localStorage.setItem('link::products::columns', JSON.stringify(this.columns));
    },
    loadColumnSettings(){
      let savedColumns = localStorage.getItem('link::products::columns');
      console.log(savedColumns);
      if(savedColumns !== undefined && savedColumns !== null && savedColumns !== 'null' && savedColumns !== ''){
        console.log('Updating columns');
        this.columns = JSON.parse(savedColumns);
      }
    },

  }
}
</script>